<template lang="pug">
.menu-column.is-flex
  h3.menu-column-title.is-flex(@click="onTitleClickHandler", :class="{open: expanded}")
    span {{ menu.name }}
    i.fa.fa-angle-down.is-hidden-tablet(:class="{open: expanded}")
  collapse-transition
    .menu-column-items(v-show="expanded", :class="{'show-item': expandedClass}")
      v-link.menu-column-item(v-for="item in menu.items", :key="item.id", :to="item.link", :type="item.type", :target="item.target") {{ item.name }}
</template>

<script>
import { CollapseTransition } from 'vue2-transitions'

export default {
  name: 'FooterMenuColumnComponent',

  components: {
    CollapseTransition
  },

  props: {
    menu: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    expanded: false,
    expandedClass: false
  }),

  methods: {
    onTitleClickHandler() {
      if (!this.$device.isMobile) return
      this.expanded = !this.expanded
      if (this.expanded) {
        this.expandedClass = this.expanded
        return
      }
      // hack to keep alive the transition
      setTimeout(() => {
        this.expandedClass = this.expanded
      }, 300)
    }
  }
}
</script>

<style lang="sass" scoped>
.menu-column
  flex-direction: column
  &:last-child
    +mobile
      border-bottom: 1px solid $border-color
  &-title
    font-weight: 700
    text-transform: uppercase
    font-size: 14px
    margin-bottom: 30px
    +mobile
      margin-bottom: unset
      padding: 15px 0
      justify-content: space-between
      border-top: 1px solid $border-color
    i
      @include transition(all, .3s)
      +mobile
        margin-left: 10px
      &.open
        transform: rotate(-180deg)
  &-items
    display: flex !important
    flex-direction: column
    +mobile
      display: none !important
      padding: 20px 0
      &.show-item
        display: flex !important
    @include animated-link()
  &-item
    margin-right: auto
    color: $default-font-color
    &:not(:first-child)
      margin-top: 17px
</style>
